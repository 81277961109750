.page{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}
.spacer{
    flex: 1;
}
.heading{
    width: 90%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    padding: 12px 0px;
    border-bottom: 1px solid lightgray;
    background-color: white;
    z-index: 100;
}

.heading>button{
    padding: 8px 12px;
    background-color: black;
    border: 0;
    border-radius: 45px;
    color: white;
    font-size: medium;
    font-weight: 500;
}

.heading>div{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.heading>button:hover{
    opacity: 0.6;
}

@media screen and (min-width: 720px) {
    .heading{
        position: sticky;
        top: 0;
    }
}
.container {
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
}

.copyright {
    font-size: small;
    padding-right: 20px;
    text-align: center;
    color: #444444;
}

.links {
    flex: 1;
    /* display: flex;
    flex-direction: row;
    align-items: center; */
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(200px,1fr));
    list-style: none;
}


.link {
    text-decoration: none;
    color: #444444;
    margin: 8px 12px;
    font-size: small;
    border-bottom: 1px transparent solid;
    transition: all 0.1s ease-in-out;
    font-weight: 500;
}

.link:hover {
    border-color: #444444;
}

/* .divider {
    height: 15px;
    width: 1px;
    background-color: #444444;
} */
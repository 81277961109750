.container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.label {
    font-size: smaller;
    font-weight: 500;
}

.select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0;
    padding: 12px;
    background-color: transparent;
    border: 2px solid black;
    /* outline: none; */
    font-size: medium;
    border-radius: 5px;
}
.page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

.spacer {
    flex: 1;
}

.section {
    width: 90%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0px;
}

.form {
    display: flex;
    flex-direction: column;
}

.inputContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    border: 2px solid black;
    padding: 0px 12px;
    border-radius: 5px;
}

.inputContainer>input {
    flex: 1;
    padding: 12px;
    border: 0;
    background-color: transparent;
    outline: none;
    font-size: medium;
}

.phoneNotice {
    margin: 8px 5px;
    color: gray;
}

.buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.backBtn {
    border: 0;
    height: 40px;
    width: 40px;
    background-color: lightgray;
    border-radius: 45px;
    font-size: large;
    transition: all 0.3s ease-in-out;
}

.backBtn:hover {
    opacity: 0.6;
}

.backBtn:disabled {
    opacity: 0.6;
}


.submitBtn {
    background-color: black;
    border: 0;
    padding: 10px 20px;
    color: white;
    font-weight: 500;
    font-size: medium;
    border-radius: 45px;
    transition: all 0.3s ease-in-out;
}

.submitBtn:focus {
    opacity: 0.6;
}

.submitBtn:disabled {
    opacity: 0.5;
}

.submitBtn>svg {
    margin-left: 8px;
}

.signIn{
    margin-top: 20px;
    align-self: center;
    padding: 20px 0px;
    display: flex;
    gap: 10px;
}

.signIn>a{
    text-decoration: none;
    font-weight: 500;
}
.container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 12px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: green;
    color: white;
    z-index: 1000;
    gap: 20px;
}

.error{
    background-color: red;
}

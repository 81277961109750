.section {
    width: 90%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.forms {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
}

.formSection {
    margin: 12px 0px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.pickBtn,
.pickBtnAfter {
    min-height: 130px;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dotted gray;
    gap: 5px;
}

.uploaded {
    pointer-events: none;
}

.pickBtnContainer{
    position: relative;
}

.pickBtnContainer>small{
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    color: red;
}

.pickBtn>svg,
.pickBtnAfter>svg {
    height: 40px;
    width: 40px;
}

.pickBtn>small,
.pickBtnAfter>small {
    text-align: center;
    font-size: small;
}

.uploadFailed{
    border-color: red;
    color: red;
}

.uploadingContainer{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(231, 231, 231, 0.07);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    border: 2px dotted blue;
    color: blue;
    z-index: 10;
}

.uploadingContainer>svg{
    width: 20px;
    height: 20px;
}

.itemButtons{
    display: flex;
    align-items: center;
    gap: 8px;
}

.itemBtn{
    background-color: transparent;
    border: 0;
    border-radius: 45px;
    padding: 3px 8px;
}

.itemBtnRemove{
    background-color: lightgray;
    color: red;
}

.itemBtnView{
    background-color: lightblue;
}

.buttons {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.backBtn {
    border: 0;
    height: 40px;
    width: 40px;
    background-color: lightgray;
    border-radius: 45px;
    font-size: large;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
}

.backBtn:hover {
    opacity: 0.6;
}

.backBtn:disabled {
    opacity: 0.6;
}

.skipBtn {
    background-color: transparent;
    border: 0;
    font-size: medium;
}

.submitBtn {
    background-color: black;
    border: 0;
    padding: 10px 20px;
    color: white;
    font-weight: 500;
    font-size: medium;
    border-radius: 45px;
    transition: all 0.3s ease-in-out;
}

.submitBtn:focus {
    opacity: 0.6;
}

.submitBtn:disabled {
    opacity: 0.5;
}

.submitBtn>svg {
    margin-left: 8px;
}
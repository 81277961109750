.page {
    width: 90%;
    max-width: 900px;
    padding: 20px 0px;
}

.section {
    display: flex;
    flex-direction: column;
}

.heading{
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.form>button {
    background-color: blue;
    color: white;
}

.form>button:disabled {
    pointer-events: none;
    opacity: 0.6;
}

.sectionInput {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.title>span:nth-child(2) {
    color: red;
}

.inputs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}
.container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.address {
    margin: 0px;
    line-height: 24px;
    color: #444444;
}

.headerTitle{
    font-size: large;
}
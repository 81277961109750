.page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    padding: 20px 0px;
}


.spacer {
    flex: 1;
}

.section {
    width: 90%;
    flex: 1;
    max-width: 960px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
}

.formSection {
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.forms {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.inputContainer>input,
select {
    padding: 12px;
    border: 2px solid black;
    border-radius: 5px;
    background-color: transparent;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: medium;
}

.inputContainer>input:disabled {
    border-color: lightgray;
}

.inputContainer>label {
    font-size: smaller;
    font-weight: 500;
}

.buttons {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.backBtn {
    border: 0;
    height: 40px;
    width: 40px;
    background-color: lightgray;
    border-radius: 45px;
    font-size: large;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
}

.backBtn:hover {
    opacity: 0.6;
}

.backBtn:disabled {
    opacity: 0.6;
}

.submitBtn {
    background-color: black;
    border: 0;
    padding: 10px 20px;
    color: white;
    font-weight: 500;
    font-size: medium;
    border-radius: 45px;
    transition: all 0.3s ease-in-out;
}

.submitBtn:focus {
    opacity: 0.6;
}

.submitBtn:disabled {
    opacity: 0.5;
}

.submitBtn>svg {
    margin-left: 8px;
}
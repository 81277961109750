.main{
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container{
    width: 90%;
    max-width: 960px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.container > h1{
    font-size: 3em;
    margin: 0;
}

.container > p{
    font-size: large;
    margin: 0;
    font-weight: 500;
    text-align: center;
}

.container > button{
    margin-top: 20px;
    background-color: transparent;
    border: 0;
    font-size: medium;
    font-weight: 500;
    color: rgb(0, 0, 112);
}

@media only screen and (max-width: 900px){
    .container > h1{
        font-size: 2em;
        margin: 0;
    }
}
.page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    overflow: auto;
}

.container {
    width: 90%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
}


.buttonContainer {
    background-color: white;
    padding: 12px 0px;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.buttonContainer>p {
    text-align: center;
    font-size: small;
}


.buttonContainer>button {
    background-color: blue;
    color: white;

}

.feature {
    display: flex;
    align-items: center;
    column-gap: 50px;
    row-gap: 20px;
    background-color: rgb(0, 0, 0);
    color: white;
}

.featureTexts {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 20px;
    box-sizing: border-box;
}

.featureTexts>p {
    font-size: large;
    line-height: 24px;
}

.image {
    width: 50%;
    height: calc(30vw + 100px);
    max-height: 400px;
    object-fit: cover;
}

.section {
    align-self: center;
    padding: 40px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.sectionList {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 40px;
}


.sectionItem {
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: rgb(241, 241, 241);
    padding: 8px 12px;
}

.sectionItem>p {
    /* text-align: justify; */
    line-height: 24px;
}


.sectionList2 {
    padding: 40px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.list>div {
    display: flex;
    gap: 5px;
}

.downloadContainer {
    width: 100%;
    position: sticky;
    top: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    /* row-gap: 5px; */
    column-gap: 20px;
    padding: 8px 20px;
    background-color: white;
    box-sizing: border-box;
    align-items: center;
    background-color: black;
}

.downloadBtn {
    align-self: flex-start;
    margin: 12px 0px;
    background-color: white;
    color: black;
    font-size: large;
    font-weight: 500;
    border: 0;
    color: 0;
    padding: 12px;
}


@media screen and (max-width:600px) {
    .container {
        width: 100%;
    }

    .section {
        width: 90%;
    }

    .feature {
        flex-direction: column;
    }

    .featureTexts {
        width: 100%;
    }

    .image {
        width: 100%;
    }
}
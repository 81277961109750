.menu {
    width: 20px;
    height: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .line {
    width: 100%;
    height: 3px;
    background-color: #000;
    transition: all 0.3s ease;
    border-radius: 45px;
  }
  
  .line.opened:nth-child(1) {
    transform: rotate(45deg);
    transform-origin: 5% 50%;
  }
  
  .line.opened:nth-child(2) {
    opacity: 0;
  }
  
  .line.opened:nth-child(3) {
    transform: rotate(-45deg);
    transform-origin: 5% 50%;
  }
  
.container {
    width: 90%;
    max-width: 1080px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 20px;
    padding: 40px 0px;
}

.section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.headerTitle{
    font-size: large;
}


.links {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: all 0.3s ease-in-out;
    gap: 12px;
}
.link {
    text-decoration: none;
    color: #444444;
    border-bottom: 1.5px transparent solid;
    transition: all 0.1s ease-in-out;
    background-color: transparent;
    font-size: medium;
    font-weight: 500;
}

.link:hover {
    border-color: #444444;
}
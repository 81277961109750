.section {
    width: 90%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0px;
}

.form {
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
}

.formSection {
    margin: 20px 0px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}



.buttons {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.backBtn {
    border: 0;
    height: 40px;
    width: 40px;
    background-color: lightgray;
    border-radius: 45px;
    font-size: large;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
}

.backBtn:hover {
    opacity: 0.6;
}

.backBtn:disabled {
    opacity: 0.6;
}

.skipBtn {
    border: 0;
    background-color: transparent;
    font-size: medium;
}

.submitBtn {
    background-color: black;
    border: 0;
    padding: 10px 20px;
    color: white;
    font-weight: 500;
    font-size: medium;
    border-radius: 45px;
    transition: all 0.3s ease-in-out;
}

.submitBtn:focus {
    opacity: 0.6;
}

.submitBtn:disabled {
    opacity: 0.5;
}

.submitBtn>svg {
    margin-left: 8px;
}
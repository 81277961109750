.page {
    height: 100%;
    width: 90%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    /* gap: 40px; */
    padding: 20px 0px;
}

.page>h1{
    margin-bottom: 40px;
}

.notice{
    margin-top: 40px;
    font-size: small;
}

.page>button {
    margin-top: 20px;
    background-color: blue;
    color: white;
}

.section {
    flex: 1;
    display: flex;
    flex-direction: column;
}


.form {
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    border-bottom: 1.5px solid lightgray;
}

.heading {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.heading>p {
    font-size: small;
}

.statusText {
    align-self: flex-start;
    font-size: small;
    text-align: center;
    background-color: lightgray;
    padding: 5px 12px;
    border-radius: 45px;
    font-weight: 500;
}

.failed {
    background-color: rgb(255, 226, 226);
    color: red;
}

.uploaded {
    background-color: rgb(225, 225, 225);
    color: green;
}

.pickedContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.pickedValue {
    text-align: center;
    font-size: small;
}

.pickBtn {
    align-self: flex-end;
    background-color: lightblue;
    padding: 5px 12px;
    border-radius: 45px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: small;
}

.removeBtn {
    align-self: flex-end;
    background-color: lightcoral;
    border: 0;
    padding: 5px 12px;
    border-radius: 45px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: small;
}


.alertDialog {
    width: 90%;
    max-width: 400px;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.alertDialog>button:nth-child(1){
    align-self: flex-end;
}

.alertDialog>button:last-child{
    margin-top: 20px;
    background-color: blue;
    color: white;
}

.emptylist{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.emptylist>p{
    color: red;
}
.page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    overflow-y: auto;
    padding: 20px 0px;
}

.spacer {
    flex: 1;
}

.section {
    max-width: 400px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.forgotPassword {
    text-decoration: none;
    padding: 0px 5px;
    font-weight: 600;
}

.submitBtn {
    padding: 12px 12px;
    background-color: black;
    color: white;
    font-weight: 600;
    border-radius: 12px;
    border: 0;
    font-size: medium;
}

.submitBtn:hover {
    opacity: 0.8;
}

.regText{
    padding: 0px 8px;
}

.regText>a {
    margin-left: 8px;
    font-weight: 600;
    text-decoration: none;
}
.btn{
    border: 0;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.btn svg{
    height: 16px;
    width: 16px;
}

.btn img{
    height: 18px;
    width: 18px;
}

.btn span{
    font-size: medium;
}

.btn:hover{
    opacity: 0.7;
}

.withoutLabel{
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    border-radius: 45px;
    padding: 0;
}

.withBackground{
    background-color: rgb(221, 221, 221);
}

.withoutBackground{
    background-color: transparent;
}

.rounded{
    border-radius: 45px;
}

.norounded{
    border-radius:  12px;
}
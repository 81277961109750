.page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    gap: 20px;
}

.heading {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 1000;
}

.headingContainer {
    width: 100%;
    max-width: 1280px;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 5px 0px;
}

.headingContainer>svg {
    font-size: x-large;
    padding: 12px;
}

.headingContainer>h1 {
    font-size: x-large;
}

.emailInputContainer {
    width: 100%;
    position: sticky;
    bottom: 0;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
    padding: 8px 12px;
    background-color: white;
}

.emailInputContainer>input {
    background-color: rgb(234, 234, 234);
    padding: 8px 12px;
    border: 0;
    border-radius: 12px;
    font-size: medium;
}

.emailInputContainer>button {
    min-width: max-content;
    border: 0;
    background-color: black;
    padding: 8px 12px;
    border-radius: 12px;
    color: white;
    font-size: medium;
}

.notice {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #CCCFC7;
    background: linear-gradient(to top right, #CCCFC7 0%, #afaf44 100%);
    padding: 20px 12px;
    border-radius: 8px;
    margin: 20px 0px;
    gap: 20px;
}

.notice>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.section {
    max-width: 1280px;
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.app {
    margin: 40px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.downloadBtnContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.downloadBtnContainer>div {
    display: flex;
    align-items: center;
    gap: 12px;
}

.downloadBtnContainer>div>h3 {
    font-size: x-large;
    font-weight: 600;
}

.appIcon {
    height: 60px;
    width: 60px;
    border-radius: 12px;
    border: 0;
    overflow: hidden;
    object-fit: cover;
}

.downloadBtn {
    background-color: black;
    color: white;
    padding: 12px;
    font-size: medium;
    border-radius: 8px;
    border: 0;
}

.screenshots {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    overflow-x: auto;
    background-color: rgb(238, 238, 238);
    padding: 12px;
    border-radius: 12px;
    box-sizing: border-box;
}

.screenshot {
    width: 200px;
    border-radius: 8px;
    object-fit: contain;
}

.instructionContainer {
    display: flex;
    flex-direction: column;
    padding: 12px 0px;
    gap: 20px;
}

.instructions {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.instructionItems {
    display: flex;
    flex-direction: column;
    gap: 12px;
}



.step::before {
    position: absolute;
    content: '';
    height: 5px;
    width: 5px;
    background-color: black;
    top: 8px;
    left: 0;
}

.appfeatures {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.featureItem {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.features {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.features>p {
    position: relative;
    padding-left: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
}

@media screen and (max-width:440px) {
    .emailInputContainer {
        padding: 8px 0px;
        gap: 8px;
    }

    .emailInputContainer>input,
    .emailInputContainer>button {
        width: 100%;
    }
}
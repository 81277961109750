.container{
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.container > svg{
    font-size: 4em;
}
.main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: slideDown 0.5s ease-out;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes slideDown {
    0% {
        transform: translateY(-60px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
.container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
}

.inputContainer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
}

.label {
    font-size: smaller;
    font-weight: 500;
}

.input {
    width: 100%;
    padding: 12px;
    border: 0;
    background-color: transparent;
    font-size: medium;
    background-color: transparent;
    border: 2px solid black;
    border-radius: 5px;
}

.lockIcon {
    position: absolute;
    right: 5px;
    height: 40px;
    width: 40px;
    border-radius: 45px;
    border: 0;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    background-color: white;
}

.lockIcon svg {
    height: 16px;
    width: 16px;
    color: gray;
}
.page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    gap: 20px;
}

.heading {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 1000;
}

.headingContainer {
    width: 100%;
    max-width: 1280px;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 5px 0px;
}

.headingContainer>svg {
    font-size: x-large;
    padding: 12px;
}

.headingContainer>h1 {
    font-size: x-large;
}

.notice {
    /* max-width: 400px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #CCCFC7;
    background: linear-gradient(to top right, #CCCFC7 0%, #afaf44 100%);
    padding: 20px 12px;
    border-radius: 8px;
    gap: 20px;
    margin: 12px 0px;
}

.notice>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.section {
    max-width: 1280px;
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.app {
    margin: 40px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: rgb(236, 236, 236);
    padding: 20px 0px;
    border-radius: 8px;
}

.downloadBtnContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    gap: 20px;
    padding: 0px 20px;
}

.downloadBtnContainer>div {
    display: flex;
    align-items: center;
    gap: 12px;
}

.downloadBtnContainer>div>h3 {
    font-size: x-large;
    font-weight: 600;
}

.appIcon {
    height: 60px;
    width: 60px;
    border-radius: 12px;
    border: 0;
    overflow: hidden;
    object-fit: cover;
}

.downloadBtn {
    background-color: black;
    color: white;
    padding: 12px;
    font-size: medium;
    border-radius: 12px;
    border: 0;
}

.screenshots {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    overflow-x: auto;
    background-color: black;
    padding: 12px;
    box-sizing: border-box;
}

.screenshot {
    width: 250px;
    height: 450px;
    border-radius: 8px;
    object-fit: contain;
}

.instructionContainer {
    display: flex;
    flex-direction: column;
    padding: 12px 20px;
    gap: 12px;
}

.installationSteps {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.step {
    /* padding: 12px 0px; */
    position: relative;
    padding-left: 12px;
}

.step::before {
    position: absolute;
    content: '';
    height: 5px;
    width: 5px;
    background-color: black;
    top: 8px;
    left: 0;
}

.appfeatures {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 20px;
}

.featureItem {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.features {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.feature{
    display: flex;
    flex-wrap: wrap;
    column-gap: 12px;
    padding: 0px 20px;
}
.spacer {
    flex: 1;
}

.section {
    width: 90%;
    max-width: 400px;
    background-color: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 20px;
    box-sizing: border-box;
}

.heading {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.heading>div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.changeBtn{
    background-color: transparent;
    border: 0;
    font-size: medium;
    font-weight: 500;
    color: rgb(3, 3, 129);
    text-align: end;
}

.changeBtn:hover{
    opacity: 0.6;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form>button {
    background-color: black;
    color: white;
    font-weight: 500;
}

.otp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.input {
    height: 40px;
    width: 40px;
    text-align: center;
    font-size: medium;
    border: 2px solid gray;
    border-radius: 5px;
}


.noOtp{
    display: flex;
    align-items: center;
    gap: 8px;
}

.noOtp>button{
    background-color: transparent;
    border: 0;
    font-size: medium;
    color: rgb(0, 0, 120);
}

.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divider{
    height: 1px;
    width: 100%;
    background-color: #cccccc3c;
}
.loading {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.container {
    flex: 1;
    max-width: 960px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.spacer {
    flex: 1;
}

.header {
    position: sticky;
    top: 0;
    padding: 20px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    z-index: 10;
    box-shadow: 0px 3px 3px -1px rgba(231, 231, 231, 0.55);
}

.header>button {
    background-color: rgba(255, 0, 0, 0.17);
    color: red;
    font-weight: 500;
}

.links {
    display: flex;
    align-items: center;
    gap: 20px;
}

.menu {
    position: relative;
    text-decoration: none;
    color: black;
}

.menu:hover {
    text-decoration: underline;
}

/* .menu::after{
    position: absolute;
    content: "*";
    top: 0;
    color: red;
} */

.section {
    align-self: center;
    width: 90%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.profilePicture {
    align-self: center;
    height: 200px;
    width: 200px;
    object-fit: cover;
    /* clip-path: circle(); */
    margin-top: 40px;
    margin-bottom: 20px;
    border-radius: 8px;
}

.nameContact {
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.contacts {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 12px;
}

.contacts>p {
    display: flex;
    align-items: center;
    gap: 12px;
}

.driverPerformance {
    margin: 40px 0px;
    max-width: 500px;
    align-self: center;
    display: flex;
    justify-content: space-between;
    gap: 40px;
    background-color: rgb(231, 231, 231);
    padding: 12px 40px;
    border-radius: 45px;
}

.driverPerformance>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.driverPerformance>div>p:nth-child(1) {
    font-size: large;
    font-weight: 600;
}

.driverPerformance>div>p:nth-child(2) {
    font-size: small;
}


.docsContainer {
    width: 100%;
    padding: 40px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background: linear-gradient(to right, #ffffff 0%, rgb(203, 203, 203) 50%, #ffffff 100%);
}

.docs {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 20px;
}

.docs>a {
    color: black;
    text-decoration: none;
}

.docs>a:hover {
    text-decoration: underline;
}

.logoutBtn {
    margin: 20px 0px;
    align-self: center;
    background-color: red;
    color: white;
    border: 0;
    padding: 8px 12px;
    border-radius: 45px;
    font-size: medium;
    font-size: 500;
}
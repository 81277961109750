.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.147);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.section {
    width: 90%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    background-color: white;
    box-shadow: 1px 1px 5px 1px rgb(192, 192, 192);
    overflow: hidden;
}

.section>h3 {
    padding:8px 12px;
}

.section>p {
    padding:8px 12px;
    font-size: smaller;
}

.buttons {
    width: 100%;
    margin-top: 8px;
    border-top: 1px solid lightgray;
    display: flex;
}

.buttons>button {
    flex: 1;
    background-color: transparent;
    border: 0;
    padding:8px 12px;
    font-size: medium;
}

.buttons>button:hover{
    background-color: rgb(231, 231, 231);
}

.divider{
    width: 1px;
    background-color: lightgray;
    color: red;
}
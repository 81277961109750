.page {
    width: 90%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section {
    display: flex;
    flex-direction: column;
}

.sectionItem1 {
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
}

.sectionItem2 {
    margin: 12px 0px;
    display: flex;
    flex-direction: column;
}


.sectionItem3 {
    margin: 12px 0px;
    display: flex;
    flex-direction: column;
}

.sectionItem4 {
    margin: 12px 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.section p {
    margin: 8px 0px;
    position: relative;
    padding-left: 20px;
}

.section p::before {
    position: absolute;
    content: "";
    height: 6px;
    width: 6px;
    background-color: black;
    top: 7px;
    left: 5px;
}

.sectionList1 {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.sectionList1>p {
    padding: 12px;
}
.container {
    padding: 12px 0px;
    width: 90%;
    max-width: 1280px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.toggleBtn {
    display: none;
}

.logo {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    pointer-events: all;
}

.logo>span {
    font-size: x-large;
    font-weight: bold;
    color: black;
}

.links {
    padding: 0;
    display: flex;
    flex-direction: row;
    list-style: none;
    gap: 12px;
}

.link {
    height: 40px;
    text-align: center;
    padding: 0px 12px;
    text-decoration: none;
    color: black;
    font-size: large;
    font-weight: 500;
    border-radius: 45px;
    display: inline-block;
    align-content: center;
}
/* 
.links>li:nth-child(n+5) {
    display: none;
} */


.activeUrl {
    background-color:black;
    color: white;
}

@media only screen and (max-width: 900px) {

    .hideLogo {
        opacity: 0;
        pointer-events: none;
    }

    .toggleBtn {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .links {
        position: fixed;
        top: 60px;
        left: -100%;
        right: 100%;
        bottom: 0;
        background-color: white;
        flex-direction: column;
        align-items: center;
        padding: 20px 12px;
        transition: all 0.3s ease-in-out;
    }

    .link:nth-child(n+5) {
        display: block;
    }

    .link{
        min-width: 200px;
    }

    .activeUrl {
        background-color:rgba(105, 107, 197, 0.195);
        color: rgb(0, 5, 109);
    }

    .isOpened {
        left: 0;
        right: 0;
    }
}